<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.keyword" placeholder="搜索关键字" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />
      <el-select v-model="listQuery.complainttype" placeholder="状态" clearable style="width: 150px" class="filter-item">
        <template v-for="status in statusStatus">
          <el-option :label="status.name" :value="status.value"></el-option>
        </template>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
    </div>

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="项目编号" width="90px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.projid}}</span>
        </template>
      </el-table-column>
      <el-table-column label="客服编号" width="90px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.csid}}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" width="180px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.projname}}</span>
        </template>
      </el-table-column>
      <el-table-column label="客服名称" width="130px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.realname}}</span>
        </template>
      </el-table-column>
      <el-table-column label="投诉内容"  align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column label="投诉类型" width="100px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.complainttype | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="投诉时间" align="center" width="180">
        <template slot-scope="{row}">
          <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

  </div>
</template>

<script>
  import {queryComplaint} from "@/apis/user-behavior";
  import { parseTime } from '@/utils'
  const statusOptions = [
    { key: '1', display_name: '业务投诉' },
    { key: '2', display_name: '服务态度' },
  ]
  const calendarTypeKeyValue = statusOptions.reduce((acc, cur) => {
    acc[cur.key] = cur.display_name
    return acc
  }, {})
  export default {
    name: 'complaint',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'info',
          deleted: 'danger'
        }
        return statusMap[status]
      },
      typeFilter(type) {
        return calendarTypeKeyValue[type]
      },
      parseTime:parseTime
    },
    data() {
      return {
        tableKey: 0,
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 20,
          keyword:'',
          complainttype:'',
          auid:null
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        this.listQuery.adminuid=this.user.userId
        queryComplaint(this.listQuery).then(res => {
        const {data = [], count = 0} = res;
        this.list=data;
        this.total=count;
        this.listLoading = false;
        })
      },   handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
    },computed:{
    statusStatus() {
      return [
        {name: '全部', value: null},
        {name: '业务投诉', value: 1},
        {name: '服务态度', value: 2},
      ]
    },
  }
  }
</script>

<style scoped>
.filter-item {
  margin-left:5px;
}
</style>
<style>
  .el-tooltip__popper {
    max-width: 800px;
  }
</style>
